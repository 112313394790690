@font-face {
  font-family: DM Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("dm-mono-latin-ext-400-normal.fe9a298f.woff2") format("woff2"), url("dm-mono-latin-ext-400-normal.a9879e03.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: DM Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("dm-mono-latin-400-normal.866ff5ee.woff2") format("woff2"), url("dm-mono-latin-400-normal.5dc3dfff.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: DM Mono;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("dm-mono-latin-ext-400-italic.bc6e5298.woff2") format("woff2"), url("dm-mono-latin-ext-400-italic.2236d9a8.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: DM Mono;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("dm-mono-latin-400-italic.5939a39b.woff2") format("woff2"), url("dm-mono-latin-400-italic.a9577254.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/*# sourceMappingURL=index.a5803600.css.map */
